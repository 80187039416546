<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Edit Meetup') }}</h2>
    <FormMeetups :baseMeetup="meetup" @submit-requested="submitRequested"></FormMeetups>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormMeetups from '../forms/FormMeetups.vue'
import router from '@/router';

  
  export default {  
    data () {
    return {
      meetup: null
    }
    
  },
  components: { FormMeetups },
  computed:{
    ...mapState('meetups', ['meetupOutput']),
  },
  methods: {
    ...mapActions('meetups', ['editMeetups']),
    ...mapActions('meetups', ['getList']),
    submitRequested (meetup) {
      this.editMeetups(meetup);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.meetup)
    {
      router.push({ path: '/meetups/list' });
    }
    else
    {
      this.meetup = this.$route.params.meetup;
    }
  }
}
</script> 
 
<style scoped>
</style> 